<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular
        style="z-index: 99"
        :size="70"
        :width="10"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      style="border-radius: 20px"
      class="hide-scrollbar"
      v-model="dialog"
      max-width="500"
    >
      <v-card style="border-radius: 20px">
        <div justify="center">
          <v-card-title class="d-flex justify-center align-center">
            <div class="text my-3">Company</div>
            <div class="text mx-3" style="color: #2a95ae">Analytics</div>
          </v-card-title>
        </div>
        <v-card-text
          class="hide-scrollbar"
          style="max-height: 500px; overflow-y: auto; overflow-x: auto"
        >
          <!-- Department Percentage Chart -->
          <apexchart
            type="pie"
            :options="deptChartOptions"
            :series="deptChartSeries"
          ></apexchart>
          <apexchart
            type="pie"
            :options="activeInactiveChartOptions"
            :series="activeInactiveChartSeries"
          ></apexchart>

          <!-- Active/Inactive Users Chart -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#2b95ae "
            class="mx-2 my-2"
            width="150px"
            dark
            dense
            rounded
            @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col>
      <v-row v-if="showAddClub">
        <AddClub @hideChild="showAddClub = false" />
      </v-row>
      <ViewClubs
        v-if="showViewClubs"
        :time="selectedNewsletter.time"
        :imageUrl="selectedNewsletter.imageUrl"
        :location="selectedNewsletter.location"
        :end="selectedNewsletter.end"
        :category="selectedNewsletter.category"
        :bookings="selectedNewsletter.bookings"
        :start="selectedNewsletter.start"
        :name="selectedNewsletter.name"
        :description="selectedNewsletter.description"
      />
      <v-row v-if="!showAddClub && !showViewClubs">
     
        <div
          class="mx-6 my-3"
          style="
            border-radius: 20px;
            background-color: #2a95ae;
            height: 50px;
            width: 300px;
          "
        >
          <h3 class="text mx-3 my-3 white--text">{{ selectedClubName }}</h3>
        </div>
      </v-row>

      <v-row
        v-if="!showAddClub && !showViewClubs"
        justify="end"
        class="mx-1 my-1"
      >
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            class="custom-search-bar"
            v-model="searchText"
            solo
            append-icon="mdi-magnify"
            dense
            placeholder="Search"
            rounded
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="2">
          <v-tooltip bottom>
<template v-slot:activator="{ on, attrs }">
  <v-select
    v-model="selectedCategory"
    :items="categoryItems"
    solo
    dense
    placeholder="Filter by..."
    rounded
    v-bind="attrs"
    v-on="on"
  ></v-select>
</template>
<span>Select a category to filter by</span>
</v-tooltip>

  
        </v-col> -->
        <v-col cols="12" sm="12" md="2">
          <v-btn color="black" rounded dark @click.stop="dialog = true">
            Company Data
          </v-btn>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                solo
                color="#2a95ae"
                dark
                @click="
                  () => {
                    showAddClub = true;
                  }
                "
                v-bind="attrs"
                v-on="on"
              >
                Add A New Department
              </v-btn>
            </template>
            <span>Add a new category for companies to add events to</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row v-if="!showAddClub && !showViewClubs">
        <v-col
          cols="12"
          sm="12"
          md="4"
          v-for="item in filterednewsletters"
          :key="item.id"
        >
          <v-card
            class="card mx-auto pa-3"
            style="border-radius: 20px"
            hover
            max-width="350"
          >
            <!-- <v-img
              :src="item.imageUrl"
              alt="Course Picture"
              height="200px"
              :loading="isLoading"
              :transition="true"
            >
              <template #placeholder>
                <v-skeleton-loader type="image" height="200px" />
              </template>
            </v-img> -->
            <v-row
              style="
                background-color: #2a95ae;
                margin: 0px;
                color: white;
                border-radius: 20px;
              "
              justify="center"
            >
              <h2 class="text mx-3 my-1">{{ item.name }}</h2>
            </v-row>
            <v-row justify="start" style="margin: 4px">
              <div class="mx-3 my-1">
                <h5>{{ item.description }}</h5>
              </div>
            </v-row>
            <v-card-actions>
              <v-row justify="center">
                <v-col cols="6">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#2a95ae"
                        dark
                        rounded
                        width="100%"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          viewClub(item.id);
                          showViewClubs = true;
                          selectedNewsletter = item;
                        "
                      >
                        View
                      </v-btn>
                    </template>
                    <span>View events within the company category</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    rounded
                    color="black"
                    dark
                    width="100%"
                    @click="deleteSurvey(item.id)"
                  >
                    Remove
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import AddClub from "../components/Department.vue";
import ViewClubs from "../components/Users.vue";
import VueApexCharts from "vue-apexcharts";

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      showAddSurvey: false,
      loading: false,
      dialog: false,
      isLoading: false,
      searchText: "",
      loader: false,
      showViewClubs: false,
      selectedCategory: "All", // will contain the currently selected category
      categoryItems: [
        "All",
        "Education",
        "Sport",
        "Social",
        "Corporate",
        "Tech",
        "Automotive",
        "Food",
        "Animals",
        "Cultural",
        "Musical",
        "Travel",
        "Beauty",
      ], // your category options
      newsletters: [],
      selectedNewsletter: null,

      profilePicture: "",
      showAddClub: false,
      activeInactiveChartOptions: {
        chart: {
          type: "pie",
        },
        labels: ["Active Users", "Inactive Users"],
        colors: ["#00E396", "#FF4560"], // Example colors for active/inactive users
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      activeInactiveChartSeries: [0, 0], // Initially, [activeUsers, inactiveUsers]

      deptChartOptions: {
        chart: {
          type: "pie",
        },
        labels: [], // Dynamically filled with department names after data fetch
        colors: ["#008FFB", "#FFC600", "#775DD0", "#FEB019", "#FF4560"], // Example colors for department chart
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      deptChartSeries: [], // Dynamically filled with user counts per department after data fetch
    };
  },

  components: {
    AddClub,
    ViewClubs,
    apexchart: VueApexCharts,
  },
  computed: {
    filterednewsletters() {
      return this.newsletters.filter((newsletter) => {
        const matchesSearchText = newsletter.name
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
        const matchesCategory =
          this.selectedCategory === "All" ||
          newsletter.category === this.selectedCategory; // if "All" or no category is selected, all newsletters match the category filter

        return matchesSearchText && matchesCategory;
      });
    },
    selectedClubName() {
      return localStorage.getItem("selectedClubName") || "No Club Selected"; // Default message if no club is selected
    },
  },

  methods: {
    resetToDefaultState() {
    this.showAddClub = false;
    this.showViewClubs = false;
  },
    async fetchDepartmentsAndUsers() {
      this.loading = true;
      try {
        const selectedClubId = localStorage.getItem("selectedClubId");
        if (!selectedClubId) {
          console.error("No selectedClubId found in localStorage.");
          return;
        }

        // Fetch departments associated with the selectedClubId
        const deptQuery = query(
          collection(db, "department"),
          where("clubId", "==", selectedClubId)
        );
        const departmentSnapshot = await getDocs(deptQuery);
        const departmentIds = new Set(); // Store department IDs for filtering users

        const departmentCounts = {};
        const departmentNames = {};

        // Initialize department data
        departmentSnapshot.forEach((doc) => {
          const deptData = doc.data();
          departmentCounts[doc.id] = 0;
          departmentNames[doc.id] = deptData.name;
          departmentIds.add(doc.id); // Add department ID to the set
        });

        // Fetch all users
        const userSnapshot = await getDocs(collection(db, "users"));
        let activeUsers = 0;
        let inactiveUsers = 0;
        const now = new Date();
        const FIVE_DAYS = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

        // Process each user
        userSnapshot.forEach((doc) => {
          const userData = doc.data();

          // Ensure user belongs to a department within the selected club
          if (
            userData.departmentId &&
            departmentIds.has(userData.departmentId)
          ) {
            departmentCounts[userData.departmentId]++;

            // Determine if the user is active or inactive
            if (!userData.disabled) {
              if (userData.lastLogin) {
                const lastLoginDate = userData.lastLogin.toDate(); // Assuming lastLogin is a Firebase Timestamp
                if (now - lastLoginDate <= FIVE_DAYS) {
                  activeUsers++;
                } else {
                  inactiveUsers++;
                }
              } else {
                // No lastLogin data, consider user inactive
                inactiveUsers++;
              }
            }
          }
        });

        // Prepare department chart data
        const chartLabels = [];
        const chartSeries = [];
        for (const [deptId, count] of Object.entries(departmentCounts)) {
          if (count > 0) {
            // Only add departments with users
            chartLabels.push(departmentNames[deptId]);
            chartSeries.push(count);
          }
        }
        this.deptChartOptions.labels = chartLabels;
        this.deptChartSeries = chartSeries;

        // Update active/inactive user chart series data
        this.activeInactiveChartSeries = [activeUsers, inactiveUsers];
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
      this.loading = false;
    },
    getUserType() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.userType) {
        return user.userType;
      } else {
        return null;
      }
    },
    viewClub(id) {
      localStorage.setItem("selectedDepartmentId", id);

      this.showViewClubs = true;
      this.selectedNewsletter = this.newsletters.find(
        (newsletter) => newsletter.id === id
      );
    },
    async getnewsletters() {
      try {
        const userType = this.getUserType();
        let q;

        const selectedClubId = localStorage.getItem("selectedClubId"); // Retrieve the selectedClubId from localStorage

        if (userType === "Admin") {
          // If the user is an admin, fetch all documents matching the selectedClubId
          if (selectedClubId) {
            q = query(
              collection(db, "department"),
              where("clubId", "==", selectedClubId)
            );
          } else {
            q = query(collection(db, "department"));
          }
        } else {
          // If not an admin, fetch documents that the user created and match the selectedClubId
          const uid = localStorage.getItem("userId");
          if (selectedClubId) {
            q = query(
              collection(db, "department"),
              where("createdBy", "==", uid),
              where("clubId", "==", selectedClubId)
            );
          } else {
            q = query(
              collection(db, "department"),
              where("createdBy", "==", uid)
            );
          }
        }

        const querySnapshot = await getDocs(q);
        this.newsletters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async deleteSurvey(id) {
      try {
        await deleteDoc(doc(db, "department", id));
        this.newsletters = this.newsletters.filter(
          (survey) => survey.id !== id
        );
      } catch (error) {
        console.error(error);
      }
    },
    viewSurvey(id) {
      // open the view survey modal or page with the given id
    },
  },
  created() {
    this.getnewsletters();
    this.fetchDepartmentsAndUsers();
  },
};
</script>

<style scoped>
/* ----------------------------------------------
* Generated by Animista on 2023-11-27 11:8:13
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

@-webkit-keyframes card {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.card {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.card {
  cursor: pointer;
  border-radius: 15px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  overflow-y: auto; /* Vertical scrollbar will be auto */
  overflow-x: hidden; /* Horizontal scrollbar will be hidden */
}
</style>
