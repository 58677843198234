<template>
    <div v-if="iframeLink">
      <iframe :src="iframeLink" width="100%" height="500px"></iframe>
      
    </div>
  </template>
  
  <script>
  import {
    collection,
    getDocs,
    getFirestore,
    where,
    doc,
    getDoc,
    query,
  } from "firebase/firestore";
  import { firebaseApp } from "../firebaseConfig";
  
  const db = getFirestore(firebaseApp);
  
  export default {
    data() {
      return {
        iframeLink: null, // This will hold the link to be displayed in the iframe
      };
    },
    methods: {
        async fetchIframeLink() {
  const userId = localStorage.getItem("userId"); // Assuming userId is the document ID in localStorage

  if (!userId) {
    console.error("No userId found in localStorage");
    return;
  }

  // Directly fetch user document by ID
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);

  if (!userDocSnap.exists()) {
    console.error("No matching user document found");
    return;
  }

  const userDocData = userDocSnap.data();
  const departmentId = userDocData.departmentId;

  if (!departmentId) {
    console.error("User document does not contain a departmentId");
    return;
  }

  // Fetch department document using departmentId
  const departmentDocRef = doc(db, "department", departmentId);
  const departmentDocSnap = await getDoc(departmentDocRef);

  if (!departmentDocSnap.exists()) {
    console.error("No matching department found");
    return;
  }

  // Set iframe link to display
  this.iframeLink = departmentDocSnap.data().link;
}

    },
    created() {
      this.fetchIframeLink(); // Fetch the link when the component is created
    },
  };
  </script>
  