<template>

    <div class="background my-0 px-4">
        
      <v-dialog v-model="dialog" width="500px" height="500px">
            <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        style="border-radius:20px;"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="animated-icon"
          color="#2a95ae"
          size="112"
        >
          mdi-check-circle
        </v-icon>
    
        <h2 class="text mb-6 my-3">Email has been sent to</h2>
    
        <p class="text" style="color:#2a95ae">
          {{email}}
        </p>
    
    
    
        <v-divider class="mb-4"></v-divider>
    
        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="black"
            rounded
            @click="goToPage('/')"
            dark
            variant="flat"
            width="200px"
          >
          Ok
          </v-btn>
    
        </div>
      </v-sheet>
       
        </v-dialog>
        <div class="gradient">
    <div class="py-4">
        <v-img
          class="mx-auto mb-10"
          max-width="228"
          style="margin-top:50px"
        ></v-img>
    
        <div
          class="mx-auto pa-12 pb-8 text-center card"
          elevation="8"
          max-width="448"
          style="border-radius:20px;max-width:448px;background-color:white;"
          rounded="lg"
          color="white"
   
        >
        <v-row justify="center">
            <div><h2 style="color:#2a95ae" class="text">Forgot Password?</h2></div>
       </v-row>
        <v-row>
          <v-text-field
      

            outlined
            dense
            rounded
            ref="email"
            v-model="email"
            density="compact"
            label="Email address"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            style="margin-top:20px"
            class="field"
          ></v-text-field>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="7">
          <v-btn
            solo
            rounded
            block
            dark
            class="mb-8"
            color="#2a95ae"
            width="50%"
            variant="tonal"
            @click="resetPassword()"
          >
            Send
          </v-btn>
          </v-col>
          </v-row>
        </div>
      </div>
        </div>
        <v-row justify="center">
          <v-alert
            v-if="success"
            style="position: absolute; top: 50%"
            type="success"
            >{{ successMessage }}</v-alert
          >
          <v-alert v-if="error" style="position: absolute; top: 50%" type="error">{{
            errorMessage
          }}</v-alert>
        </v-row>
    </div>
    </template>
    
    <script>
    import { auth } from "../firebaseConfig.js";
    import { sendPasswordResetEmail } from "firebase/auth";
    
    export default {
      data() {
        return {
          dialog: false,
          email: "",
          password: "",
          emailRules: [
            (v) => !!v || "Email is required",
            (v) => /.+@.+\..+/.test(v) || "Email must be valid",
          ],
          error: false,
          success: false,
          errorMessage: "Something went wrong, please try again",
          successMessage: "Success",
        };
      },
      methods: {
        goToPage(path) {
          this.$router.push({ path });
        },
        // Method to send a reset password to the user
        resetPassword() {
          let emailValid = this.$refs.email.validate();
    
          if (!emailValid) {
            this.errorMessage = "Please enter a valid email!";
    
            this.displayErrorMessage();
            return;
          }
    
          sendPasswordResetEmail(auth, this.email)
            .then((userCredential) => {
              this.dialog = true;
              // Signed in
              // const user = userCredential.user;
              // console.log("Email sent res: ", user);
              //  this.$router.push('users')
            })
            .catch((error) => {
              console.log(error);
    
              this.errorMessage =
                "Failed to send password reset email, please try again!";
    
              if (
                error == "FirebaseError: Firebase: Error (auth/user-not-found)."
              ) {
                this.errorMessage =
                  "Email not found , please try a different email address!";
              }
    
              this.displayErrorMessage();
            });
        },
        // Method to display a popup error message
        displayErrorMessage() {
          let that = this;
          this.error = true;
    
          setTimeout(function () {
            that.error = false;
          }, 3000);
        },
      },
    };
    </script>
    
    <style scoped>
    .background {
      background-image: url("../assets/Background.png");
      width: 100%;
      min-height: 100vh;
      background-size: cover;
    }
    .description{
        color:white;
    };
    .header{
    text-align: center;
    };
    .gradient{

    height:50vh;
    width:100%
    };
    .card{

    }
    </style>