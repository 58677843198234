<template>
  <v-container class="add-user-container">
    <v-dialog v-model="dialog" width="500px" height="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="animated-icon"
          color="#2a95ae"
          size="112"
        >
          mdi-check-circle
        </v-icon>

        <v-row justify="center" class="text mb-6 my-2">
          <div>ADD</div>
          <div class="mx-1" style="color:#2a95ae">USER</div>
        </v-row>

        <p class="mb-4 text-medium-emphasis text-body-2">
          Are you sure you want to add
          <br>
          this User
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="#2a95ae"
            rounded
            dark
            variant="flat"
            @click="signUp()"
            width="200px"
          >
            Yes
          </v-btn>
          <v-btn
            class="text-none mx-2 my-2"
            color="black"
            rounded
            dark
            variant="flat"
            width="200px"
            @click="dialog = false"
          >
            No
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="12" md="8" class="card form-col">
        <div justify="center">
          <v-card-title class="d-flex justify-center align-center">
            <div class="text my-3">ADD</div>
            <div class="text mx-2" style="color:#2a95ae">USER</div>
          </v-card-title>
        </div>
        <v-form class="user-form">
          <v-text-field rounded v-model="email" label="Email Address" outlined dense></v-text-field>
          <v-text-field rounded v-model="cellNo" label="Cell No." outlined dense></v-text-field>
          <v-text-field rounded v-model="firstName" label="First Name" outlined dense></v-text-field>
          <v-text-field rounded v-model="lastName" label="Last Name" outlined dense></v-text-field>
          <v-text-field
            rounded
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            outlined
            dense
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="toggleShowPassword"
          ></v-text-field>

          <v-btn class="sign-up-btn" rounded @click="dialog = true" color="#2a95ae">ADD USER</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import axios from 'axios';

const auth = getAuth();
const db = getFirestore();

export default {
  data() {
    return {
      generatedOTP: '',
      showPassword: false,
      fullName: '',
      email: '',
      cellNo: '',
      firstName: '',
      lastName: '',
      password: '',
      otpDialog: false,
      dialog: false,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be at least 6 characters',
      ],
      enteredOTP: '',
      departmentName: '',
    };
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, this.auth);
    this.recaptchaVerifier.render();
    this.getDepartmentName();
  },
  methods: {
    async getDepartmentName() {
      const selectedDepartmentId = localStorage.getItem('selectedDepartmentId');
      if (selectedDepartmentId) {
        const departmentDoc = await getDoc(doc(db, "departments", selectedDepartmentId));
        if (departmentDoc.exists()) {
          this.departmentName = departmentDoc.data().name;
        } else {
          console.error("No department found with the given ID.");
        }
      }
    },
    sendOTP() {
      const otp = Math.floor(100000 + Math.random() * 900000);
      this.generatedOTP = otp.toString();
      this.simulateOTPSending(otp, this.cellNo);
      this.otpDialog = true;
      console.log('Generated OTP:', otp);
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    submitOTP() {
      if (this.enteredOTP === this.generatedOTP) {
        this.signUp();
      } else {
        alert("Incorrect OTP. Please try again.");
      }
    },
    async signUp() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        await this.createUserRecord(user.uid);
        this.$router.push('/home');
      } catch (error) {
        console.error("Error signing up:", error.message);
      }
    },
    async createUserRecord(userId) {
      try {
        const selectedDepartmentId = localStorage.getItem('selectedDepartmentId');
        if (!selectedDepartmentId) {
          console.error("No selectedDepartmentId found in localStorage.");
          alert("Please select a department.");
          return;
        }

        await setDoc(doc(db, "users", userId), {
          fullName: this.fullName,
          email: this.email,
          cellNo: this.cellNo,
          firstName: this.firstName,
          lastName: this.lastName,
          departmentId: selectedDepartmentId,
          dateCreated: new Date()
        });
        
        this.sendSMS(this.cellNo, this.firstName, this.lastName, this.email, this.password);

        console.log("User record created in Firestore with departmentId");
        this.$router.push('/dashboard');
      } catch (error) {
        console.error("Error creating user record:", error.message);
        alert("Failed to create user account. Please try again.");
      }
    },
    async sendSMS(cellNo, firstName, lastName, email, password) {
      const apiURL = 'https://rest.smsportal.com/v1/bulkmessages';
      const apiKey = 'a653c9fa-e9f7-40f8-b039-01fc7ecabf8f';
      const apiSecret = '122b0349-2a95-4d75-acd5-b279ae05f975';
      const company = 'Your Company';
      const hybridLink = 'https://play.google.com/store/apps/details?id=co.za.rightclickmedia.pocketmanagerk';
      const hybridLink1 = 'https://apps.apple.com/za/app/pocket-manager-ai/id6499519128';

      const PWA = 'https://app.pocketmanager.io';
      const message = `Join Pocket Manager by Downloading the app ${hybridLink} for android and for ios ${hybridLink1}  or access the website ${PWA}. Login: Username ${email}, Password ${password}.`;

      try {
        const response = await axios.post(apiURL, {
          messages: [
            {
              content: message,
              destination: cellNo,
            },
          ],
        }, {
          headers: {
            'Authorization': 'Basic ' + btoa(apiKey + ':' + apiSecret),
          },
        });

        console.log('SMS sent successfully:', response.data);
      } catch (error) {
        console.error('Failed to send SMS:', error);
      }
    },
    simulateOTPSending(otp, cellNo) {
      console.log(`Sending OTP ${otp} to ${cellNo}`);
    }
  }
};
</script>

<style scoped>
.add-user-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.form-col {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}

.user-form {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 10px;
}

.sign-up-btn, .submit-btn {
  margin-top: 20px;
  color: #fff;
  background-color: #1976D2;
  font-weight: bold;
}

.otp-card {
  padding: 20px;
}
</style>
