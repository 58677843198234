<template>
  <div class="backgroundStyle d-flex justify-center align-center">
    <v-row justify="center">
      <v-col
        cols="10"
        sm="10"
        md="3"
        justify="center"
        class="font-family: 'Poppins', sans-serif;"
      >
        <v-card
          class="card d-flex justify-center align-center pa-3"
          style="border-radius: 20px;"
        >
          <!-- Logo Card Start -->
          <v-col>
            <v-row justify="center" class="mb-5">
              <v-img
                src="../assets/Pocket.png"
                contain
                height="200"
                width="100"
              ></v-img>
            </v-row>

            <v-row
              justify="center"
              class="d-flex align-center font-family: 'Poppins'"
              style="margin-bottom: 20px"
            >
              <v-text-field
                v-model="email"
                dense
                required
                outlined
                rounded
                block
                label="Email"
                type="text"
                size="x-large"
                style="max-width: 301px; color: #EEBF00;"
                :rules="emailRules"
              ></v-text-field>
            </v-row>

            <v-row
              justify="center"
              class="d-flex align-center font-family: 'Poppins'"
              style="margin-bottom: 20px"
            >
              <v-text-field
                v-model="password"
                dense
                required
                outlined
                rounded
                block
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePasswordVisibility"
                style="max-width: 301px; color: #EEBF00;"
                :rules="passwordRules"
              ></v-text-field>
            </v-row>

            <div style="max-width: 161px; margin: 0 auto;">
              <v-btn
                @click="login"
                color="#2a95ae"
                block
                dark
                rounded
                dense
              >
                Login
              </v-btn>
            </div>
            <v-row class="my-4" justify="center">
              <h5 @click="goToPage('/forgotPassword')">Forgot Password ?</h5>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { firebaseApp } from "../firebaseConfig";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore"; // Import getDoc

export default {
  data() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return {
      backgroundStyle: {
        "background-image":
          "url(" + require("../assets/Background.webp") + ")",
        "background-size": "cover",
      },
      email: "",
      password: "",
      showPassword: false,
      emailRules: [
        v => !!v || "Email is required",
        v => emailPattern.test(v) || "Invalid email address",
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => v.length >= 6 || "Password must be at least 6 characters",
      ],
    };
  },
  computed: {
    isLoginFormValid() {
      return (
        this.email.trim() !== "" && this.password.trim() !== ""
      );
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
    async login() {
      if (this.isLoginFormValid) {
        const auth = getAuth(firebaseApp);
        const db = getFirestore(firebaseApp); // Initialize Firestore

        try {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            this.email,
            this.password
          );
          const user = userCredential.user;

          // Check if user is disabled
          const userRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userRef);

          if (
            docSnap.exists() &&
            docSnap.data().disabled
          ) {
            console.error("Account is disabled.");
          } else {
            await setDoc(
              userRef,
              { lastLogin: serverTimestamp() },
              { merge: true }
            );
            console.log("lastLogin updated successfully");
            localStorage.setItem("userId", user.uid);
            this.$router.push("/home");
          }
        } catch (error) {
          console.error(
            "Authentication failed:",
            error.code,
            error.message
          );
        }
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.backgroundStyle {
  width: 100%;
  height: 102vh;
  background: url("../assets/Background.png")
    center center / cover no-repeat;
}
</style>
