<template>
    <v-container>
      <v-col>
        <v-overlay :value="loading">
        <v-progress-circular
          style="z-index:99"
          :size="70"
          :width="10"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-icon
      v-if="isAnyComponentActive"
      color="#2a95ae"
      style="font-size:40px"
      @click="resetToDefaultState"
    >mdi-arrow-left-circle</v-icon>
        <v-row v-if="showAddClub">    
          
   <AddClub v-if="showAddClub" @hideChild="showAddClub = false" />        </v-row>
   <ViewClubs v-if="showViewClubs"
      :time="selectedNewsletter.time"
      :imageUrl="selectedNewsletter.imageUrl"
      :location="selectedNewsletter.location"
      :end="selectedNewsletter.end"
      :category="selectedNewsletter.category"
      :bookings="selectedNewsletter.bookings"
      :start="selectedNewsletter.start"
      :name="selectedNewsletter.name"
      :description="selectedNewsletter.description" />

        <v-row v-if="!showAddClub && !showViewClubs" justify="end" class="mx-1 my-1">
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              class="custom-search-bar"
              v-model="searchText"
              solo
              append-icon="mdi-magnify"
              dense
              rounded
              placeholder="Search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-select
      v-model="selectedCategory"
      :items="categoryItems"
      solo
      dense
      rounded
      placeholder="Filter by..."
      v-bind="attrs"
      v-on="on"
    ></v-select>
  </template>
  <span>Select a category to filter by</span>
</v-tooltip>

    
          </v-col>
          <v-col>
            <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      rounded
      solo
      color="#2a95ae "
      
      dark
      @click="() => {showAddClub = true;}"
      v-bind="attrs"
      v-on="on"
    >
    Add A New Company
    </v-btn>
  </template>
  <span>Add a new category for companies to add events to</span>
</v-tooltip>

          </v-col>
        </v-row>
      
        <v-row v-if="!showAddClub && !showViewClubs">
          <v-col cols="12" sm="12" md="4" v-for="item in filterednewsletters" :key="item.id">
            <v-card class="card mx-auto pa-3" style="border-radius:20px" hover max-width="350">
              <v-img
                :src="item.imageUrl"
                alt="Course Picture"
                height="200px"
                :loading="isLoading"
                :transition="true"
              >
                <template #placeholder>
                  <v-skeleton-loader type="image" height="200px" />
                </template>
              </v-img>
              <v-row style="background-color:#2a95ae ;margin:0px;color:white" justify="start" >
                <div  class="mx-3 my-1">{{ item.name }}</div>
              </v-row>
                        <v-row justify="start" style="margin: 4px">
                <div class="mx-3 my-1"><h5>{{ item.description }}</h5></div>
              </v-row>
              <v-card-actions>
                <v-row justify="center">
                  <v-col cols="6">
                    <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="#2a95ae "
      dark
      rounded
      width="100%"
      v-bind="attrs"
      v-on="on"
      @click="
        viewClub(item.id);
        showViewClubs = true;
        selectedNewsletter = item;
      "
    >
      View
    </v-btn>
  </template>
  <span>View events within the company category</span>
</v-tooltip>

                  </v-col>
                  <v-col  cols="6">
                    <v-btn rounded color="black" dark width="100%" @click="deleteSurvey(item.id)">
                      Remove
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      </v-container>
    </template>
    
    <script>
    import {
      collection,
      getDocs,
      getFirestore,
      doc,
      query,
      where,
      deleteDoc,
    } from "firebase/firestore";
    import { firebaseApp } from "../firebaseConfig";
    import AddClub from '../components/AddClub.vue';
    import ViewClubs from '../components/ViewClubs.vue';
    const db = getFirestore(firebaseApp);
    
    export default {
      data() {
        return {
          showAddSurvey: false,
          isLoading: false,
          searchText: "",
          loader:false,
          showViewClubs: false,
          selectedCategory: 'All', // will contain the currently selected category
        categoryItems: ["All","Education", "Sport", "Social","Corporate", "Tech", "Automotive","Food", "Animals", "Cultural","Musical", "Travel", "Beauty"], // your category options
          newsletters: [],
          selectedNewsletter: null,
          loading:false,
          profilePicture: "",
          showAddClub: false,
      showViewClubs: false,
          showAddClub: false,
        };
      },
      components: {
        AddClub,ViewClubs
      },
      computed: {
      filterednewsletters() {
        return this.newsletters.filter(newsletter => {
          const matchesSearchText = newsletter.name.toLowerCase().includes(this.searchText.toLowerCase());
          const matchesCategory = this.selectedCategory === 'All' || newsletter.category === this.selectedCategory; // if "All" or no category is selected, all newsletters match the category filter
    
          return matchesSearchText && matchesCategory;
        });
      },
      isAnyComponentActive() {
      // Returns true if any specific component is currently active
      return this.showAddClub || this.showViewClubs;
    },
    },
    
    
      methods:{
        resetToDefaultState() {
    this.showAddClub = false;
    this.showViewClubs = false;
  },
        getUserType() {
          const user = JSON.parse(localStorage.getItem("user"));
          if (user && user.userType) {
            return user.userType;
          } else {
            return null;
          }
        },
        viewClub(id) {
    const selectedClub = this.newsletters.find(newsletter => newsletter.id === id);
    if (selectedClub) {
        localStorage.setItem('selectedClubId', id);
        localStorage.setItem('selectedClubName', selectedClub.name); // Store the club name in localStorage
        this.selectedNewsletter = selectedClub;
        this.showViewClubs = true;
    }
}
,
        async getnewsletters() {
  try {
    this.loading=true;
    const userType = this.getUserType();
    let q;
    
    if (userType === 'Admin') {
      // Fetch all documents if the user is an admin
      q = query(collection(db, "club"));
    } else {
      // Fetch only user's documents if not an admin
      const uid = localStorage.getItem("userId");
      q = query(collection(db, "club"), where("createdBy", "==", uid));
    }
    
    const querySnapshot = await getDocs(q);
    this.newsletters = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    this.loading=false;
  } catch (error) {
    console.error(error);
  }
},
        async deleteSurvey(id) {
          try {
            await deleteDoc(doc(db, "club", id));
            this.newsletters = this.newsletters.filter((survey) => survey.id !== id);
          } catch (error) {
            console.error(error);
          }
        },
        viewSurvey(id) {
          // open the view survey modal or page with the given id
        },
      },
      created() {
        this.getnewsletters();
      },
    };
    </script>
    
    
    <style  >

    
    /* ----------------------------------------------
 * Generated by Animista on 2023-11-27 11:8:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes card{0%{-webkit-transform:translateX(-50px);transform:translateX(-50px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}@keyframes fade-in-left{0%{-webkit-transform:translateX(-50px);transform:translateX(-50px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}
    .card{-webkit-animation:fade-in-left .6s cubic-bezier(.39,.575,.565,1.000) both;animation:fade-in-left .6s cubic-bezier(.39,.575,.565,1.000) both}
    .card {
      cursor: pointer;
      border-radius: 15px;
    }
    </style>