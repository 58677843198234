<template>
<v-container>
    <v-row justify="end">
    <v-col cols="12" sm="12" md="2">
<v-btn rounded  dark width="100%" color="#2a95ae" @click="goToPage('/dashboard')">Back</v-btn>
    </v-col>
  </v-row>
<v-row>
    <v-col>
        <div class="cardTitle">
Personal Details
        </div>
        <div class="card">
          <v-col cols="12" sm="12" md="6" class="mx-2 my-3">
       
   <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">First Name</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="First Name" v-model="firstName" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
          <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Last Name</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="Last Name" v-model="lastName" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
               <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Email Address</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="Email Address" v-model="email" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Phone Number</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="Phone Number" v-model="cellNum" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
                   <!-- <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">ID Number</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="ID Number" v-model="idNumber" rounded dense></v-text-field>
                         </v-col>
                   </v-row> -->
                
                   <v-row v-if="downloadUrls.length > 0">
  <v-col v-for="(url, index) in downloadUrls" :key="index" cols="12">
    <a :href="url" target="_blank">
      <v-btn rounded outlined width="100%" class="my-2">User Document {{ index + 1 }}</v-btn>
    </a>
  </v-col>
</v-row>


                  <!-- <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">ID Number</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field readonly outlined solo placeholder="ID Number" v-model="id" rounded dense></v-text-field>
                         </v-col>
                   </v-row> -->
                      </v-col>
        </div>
    </v-col>
</v-row>

</v-container>
</template>
<script>
import {
  collection,
  getDocs,
  getFirestore,
  where,
  query,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export default {
  components: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      cellNum: "",
      oldid: "",
      location: "",
      userType: "",
      idNumber: "",
      password: "",
      profilePicture: "",
      downloadUrls: [], // Store download URLs here
    };
  },

  methods: {
    goToPage(path) {
      this.$router.push({ path });
    },
  },
  props: {
    user: null,
  },
  async created() {
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
    this.oldid = this.user.oldid;
    this.cellNum = this.user.cellNo;
    this.idNumber = this.user.idNumber;
    this.location = this.user.location;
    this.userType = this.user.userType;
    this.profilePicture = this.user.profilePictureUrl;

    // Fetch documents from Firestore
    const q = query(collection(db, "docs"), where("user", "==", this.oldid));
    const querySnapshot = await getDocs(q);

    // Iterate through matching documents and store download URLs
    querySnapshot.forEach((doc) => {
      this.downloadUrls.push(doc.data().downloadURL);
    });
  },
};
</script>

<style scoped>
.cardTitle {
  width: 100%;
  height: 40px;
  background-color: #2a95ae;
  text-align: center;
  padding: 8px;
  color: white;
  border-radius:12px 12px 0px 0px ;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.card{
width: 100%;
  height: 100%;
  text-align: center;
  padding: 7px;
  color: white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius:0px 0px  12px 12px ;
  background-color: white;
}
.card1{
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius:12px 12px 12px 12px
}
</style>
