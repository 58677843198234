var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-container',[_c('v-dialog',{attrs:{"width":"500px","height":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-sheet',{staticClass:"pa-4 text-center mx-auto",attrs:{"elevation":"12","max-width":"600","rounded":"lg","width":"100%"}},[_c('v-icon',{staticClass:"animated-icon",attrs:{"color":"#2a95ae","size":"112"}},[_vm._v(" mdi-check-circle ")]),_c('v-row',{staticClass:"text mb-6 my-2",attrs:{"justify":"center"}},[_c('dv',[_vm._v("Add")]),_c('div',{staticClass:"mx-1",staticStyle:{"color":"#2a95ae"}},[_vm._v("Department")])],1),_c('p',{staticClass:"mb-4 text-medium-emphasis text-body-2"},[_vm._v(" Are you sure you want to add "),_c('br'),_vm._v(" a this Department ")]),_c('v-divider',{staticClass:"mb-4"}),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-none my-2",attrs:{"color":"#2a95ae","rounded":"","dark":"","variant":"flat","width":"200px"},on:{"click":function($event){_vm.saveCourse(); _vm.saveSchedule()}}},[_vm._v(" Yes ")]),_c('v-btn',{staticClass:"text-none mx-2 my-2",attrs:{"color":"black","rounded":"","dark":"","variant":"flat","width":"200px"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mx-3 my-10",attrs:{"cols":"12","sm":"12","md":"10"}},[_c('div',{staticClass:"card"},[_c('div',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"text my-3"},[_vm._v("ADD")]),_c('div',{staticClass:"text mx-2",staticStyle:{"color":"#2a95ae"}},[_vm._v("DEPARTMENT")])])],1),_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('v-text-field',{staticClass:"mx-3",attrs:{"rounded":"","dense":"","label":"Department Name","outlined":"","rules":[
      value => !!value || 'Title is required',
      value => (value && value.length <= 50) || 'Title must be less than or equal to 50 characters'
    ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('v-textarea',{staticClass:"mx-3",attrs:{"rounded":"","dense":"","outlined":"","rules":[
      value => !!value || 'Description is required',
      value => (value && value.length <= 1000) || 'Description must be less than or equal to 1000 characters'
    ],"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('v-text-field',{staticClass:"mx-3",attrs:{"rounded":"","dense":"","outlined":"","label":"Chat Bot Link"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-2 my-7",attrs:{"solo":"","color":"#2a95ae","width":"180px","dark":"","rounded":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Add Department ")])],1)],1)],1)]),_c('v-overlay',{staticStyle:{"z-index":"99"},model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}},[_c('v-progress-circular',{attrs:{"color":"white","indeterminate":"","size":70,"width":12}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }