<template>
  <v-container>
    <v-row>
      <v-overlay :value="loading">
        <v-progress-circular
          style="z-index:99"
          :size="70"
          :width="10"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-alert type="success" v-model="showSuccessAlert" dismissible>
        User approved successfully!
      </v-alert></v-row
    >
    <v-row class="pa-4" v-if="!showAddUser && !showViewUser && !showEditUser">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          rounded
          dense
          append-icon="mdi-magnify"
          placeholder="Search"
          label="Search"
          v-model="search"
          solo
          class="search-bar"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn rounded dense solo dark color="black" @click="handleBulkUpload">
          Bulk Upload
          <input
            type="file"
            id="csvFile"
            ref="fileInput"
            style="display: none"
            @change="uploadCsv"
          />
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="2">
  <v-btn rounded dark dense solo color="red" @click="handleBulkDelete">
    Bulk Delete
    <input
      type="file"
      id="csvFileDelete"
      ref="fileInputDelete"
      style="display: none"
      @change="parseCsvForDeletion"
    />
  </v-btn>
</v-col>
<v-col cols="12" sm="12" md="2">
  <v-btn
  rounded
  dense
  solo
  color="white"
  href="https://firebasestorage.googleapis.com/v0/b/trifocus-51b3c.appspot.com/o/import-emails-template%20(1)a.csv?alt=media&token=c819e307-684f-41b9-ab80-be32f3013ea1"
  target="_blank"
>
  CSV Format
</v-btn>

</v-col>
      <v-col cols="12" sm="12" md="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dense
              solo
              dark
              color="#2a95ae"
              rounded
              @click="showAddUser = true"
              v-bind="attrs"
              v-on="on"
            >
              Add New User
            </v-btn>
          </template>
          <span>Add a new user to the system</span>
        </v-tooltip>
      </v-col>


    </v-row>

    <v-row v-if="showAddUser">
      <AddUser />
    </v-row>

    <v-row v-if="showViewUser">
      <ViewUser :user="selectedUser" />
    </v-row>

    <v-row v-if="showEditUser">
      <EditUser :user="selectedUser" />
    </v-row>

    <v-row v-if="!showAddUser && !showViewUser && !showEditUser">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items-per-page="10"
          :search="search1 ? search1 : search"
          :items="users"
          :loading="loading"
          loading-text="Loading... Please wait"
          :sort-by="headers"
          class="data-table"
        >
          <template v-slot:item.dateCreated="{ item }">
            <span>
              {{ item.dateCreated ? formatDate(item.dateCreated) : "Null" }}
            </span>
          </template>

          <template v-slot:item.activeStatus="{ item }">
            <v-chip
              :color="isActive(item.lastLogin) ? 'green' : 'red'"
              text-color="white"
            >
              {{ isActive(item.lastLogin) ? "Active" : "Inactive" }}
            </v-chip>
          </template>

          <template v-slot:item.Action="{ item }">
            <v-row justify="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on">
                    <v-icon
                      color="#2a95ae"
                      @click.prevent="
                        () => {
                          showViewUser = true;
                          selectedUser = item;
                        }
                      "
                    >
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>View Details</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on">
                    <v-icon
                      color="#2a95ae"
                      @click.prevent="
                        () => {
                          showEditUser = true;
                          selectedUser = item;
                        }
                      "
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Edit User</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="disableUser(item)"
                  >
                    <v-switch
                      v-model="item.disabled"
                      @change="updateUser(item)"
                      color="#2a95ae"
                    ></v-switch>
                  </v-btn>
                </template>
                <span>Disable/Enable User</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar color="green" v-model="showBeneficiaryAddedPrompt" top>
      User disabled successfully!
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  collection,
  getDocs,
  getFirestore,
  where,
  
  setDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; // Import for Firebase Auth
import { Timestamp } from "firebase/firestore";

import AddUser from "../components/AddUser.vue";
import emailjs from 'emailjs-com';
import axios from 'axios';

import ViewUser from "../components/ViewUser.vue";
import EditUser from "../components/EditUser.vue";
import Papa from "papaparse";

const db = getFirestore(firebaseApp);


export default {
  firstName: "Clients",

  data() {
    return {
      showAddUser: false,
      showSuccessAlert: false,

      showViewUser: false,
      showEditUser: false,
      showBeneficiaryAddedPrompt: false,
      search: "",
      search1: "",
      loading: false,
      selectedUser: null,
      headers: [
        {
          text: "Date Created",
          value: "dateCreated",
          sortable: true,
          align: "center",
        },

        {
          text: "First Name",
          value: "firstName",
          sortable: true,
          align: "center",
        },

        {
          text: "Last Name",
          value: "lastName",
          sortable: true,
          align: "center",
        },

        { text: "Email", value: "email", sortable: true, align: "center" },
        //   {
        //     text: "Approved",
        //     value: "authenticated",
        //     sortable: false,
        //     align: "center",
        //   },

        {
          text: "Active Status",
          value: "activeStatus",
          sortable: false,
          align: "center",
        },

        { text: "Action", value: "Action", sortable: false, align: "center" },
      ],
      Type: ["Admin", "General User", "Client"],
      users: [],
    };
  },

  components: {
    AddUser,
    ViewUser,
    EditUser,
  },
  methods: {
    addUser() {
      this.showAddUser = true;
    },

    formatDate(timestamp) {
      if (!timestamp) return "Null"; // Return 'Null' if timestamp is not provided
      const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
      return date.toLocaleDateString("en-GB", {
        // Use 'en-GB' to ensure day-month-year order
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    handleBulkDelete() {
      this.$refs.fileInputDelete.click();
    },

    // Parses the selected CSV file
    parseCsvForDeletion(event) {
      const file = event.target.files[0];
      
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const userIdentifiers = results.data.map(user => ({ email: user.email }));
          this.triggerCloudFunctionForUserDeletion(userIdentifiers);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    },

    // Calls the bulk delete cloud function
    async triggerCloudFunctionForUserDeletion(userIdentifiers) {
      this.loading = true;

      const cloudFunctionUrl = 'https://us-central1-trifocus-51b3c.cloudfunctions.net/bulkDeleteUsers';
      
      try {
        const response = await fetch(cloudFunctionUrl, {
          method: 'POST',
          body: JSON.stringify(userIdentifiers),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        this.loading = false;

        console.log('Bulk deletion successful:', result);
        // Handle success, such as notifying the user of the deletion count
      } catch (error) {
        console.error('Error during bulk deletion:', error);
        // Handle error, such as notifying the user of the failure
      }
    },
    isActive(lastLogin) {
      if (!lastLogin) return false; // Return inactive if no lastLogin
      const lastLoginDate = new Date(lastLogin.seconds * 1000); // Convert Firestore timestamp to Date
      const now = new Date();
      const diff = now - lastLoginDate; // Difference in milliseconds
      const daysDifference = diff / (1000 * 60 * 60 * 24); // Convert to days
      return daysDifference <= 5; // Active if last login is within 5 days
    },
    // This method updates an user's disabled property on the Firestore database.
    async updateUser(item) {
      // Reference to the Firestore document of the user
      const userRef = doc(db, "users", item.id);

      // Update the 'disabled' field of the user document
      try {
        await updateDoc(userRef, {
          disabled: item.disabled,
        });
        // Show a success message or handle the UI update
        this.showBeneficiaryAddedPrompt = true;
        setTimeout(() => {
          this.showBeneficiaryAddedPrompt = false;
        }, 3000);
        // Optionally, refresh the user list or UI if necessary
        this.getUsers(); // Call getUsers if you need to refresh the list
      } catch (error) {
        console.error("Failed to update user:", error);
        // Handle any errors, such as showing an error message
      }
    },
    handleBulkUpload() {
      this.$refs.fileInput.click();
    },
    setRowClass(item) {
      return item.userType ? "" : "bg-red"; // Use your desired class name or color
    },
    uploadCsv(event) {
  const file = event.target.files[0];

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      // Store the parsed users in local storage
      localStorage.setItem('csvUsersToUpload', JSON.stringify(results.data));
      // Now call a method that triggers the cloud function
      this.triggerCloudFunctionForUserCreation();
    },
    error: (error) => {
      console.error('Error parsing CSV:', error);
    }
  });

},
async triggerCloudFunctionForUserCreation() {
  this.loading = true;

  let users = JSON.parse(localStorage.getItem('csvUsersToUpload') || '[]');

  const departmentId = localStorage.getItem('selectedDepartmentId'); 

  users = users.map(user => ({
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    dateCreated: Timestamp.fromDate(new Date()),
    phone: user.cellNum, // Ensure this is consistent with what your SMS method expects
    departmentId: departmentId,
    userType: user.userType,
  })).filter(user => user.email && user.lastName && user.password && user.firstName && user.phone);

  if (users.length === 0) {
    console.log('No valid users to upload');
    this.loading = false;
    return;
  }

  const cloudFunctionUrl = 'https://us-central1-trifocus-51b3c.cloudfunctions.net/bulkCreateUsers';

  try {
    const response = await fetch(cloudFunctionUrl, {
      method: 'POST',
      body: JSON.stringify(users),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(`HTTP ${response.status}: ${errorResponse.error.message}`);
    }

    const result = await response.json();
    console.log('Cloud function response:', result);

    this.sendEmail(); // Email sending to the created users
    await this.sendBulkSMS(users); // Bulk SMS sending to the created users

    this.loading = false;
  } catch (error) {
    console.error('Error calling cloud function via HTTP:', error);
    this.loading = false;
  }
},
async sendBulkSMS(users) {
  const messages = users.map(user => {
    const androidLink = 'https://play.google.com/store/apps/details?id=co.za.rightclickmedia.pocketmanagerk';
    const iosLink = 'https://apps.apple.com/za/app/pocket-manager-ai/id6499519128';
    const webLink = 'https://app.pocketmanager.io';
    const email = user.email;
    const password = user.password;
  
    return {
      content: `Join Pocket Manager by downloading the app:\nAndroid: ${androidLink}\niOS: ${iosLink}\nWebsite: ${webLink}\nLogin: Username ${email}, Password ${password}.`,
      destination: user.phone
    };
  });

  try {
    const apiKey = 'a653c9fa-e9f7-40f8-b039-01fc7ecabf8f';
    const apiSecret = '122b0349-2a95-4d75-acd5-b279ae05f975';
    const authHeader = 'Basic ' + btoa(`${apiKey}:${apiSecret}`);

    const response = await axios.post('https://rest.smsportal.com/v1/bulkmessages', {
      messages: messages
    }, {
      headers: {
        'Authorization': authHeader,
      },
    });

    console.log('Bulk SMS sent successfully:', response.data);
  } catch (error) {
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach(err => {
        console.error(`${err.errorCode}: ${err.errorMessage}`);
      });
    } else {
      console.error('Failed to send bulk SMS:', error);
    }
  }
}
,
async sendEmail() {
  // Initialize EmailJS
  emailjs.init("ahhJDX33aF9Z7kLEY");

  let users = JSON.parse(localStorage.getItem('csvUsersToUpload') || '[]');

  if (users.length === 0) {
    console.log('No users to send emails to');
    return;
  }

  const BATCH_SIZE = 10;  // Adjust batch size based on your rate limit requirements
  const DELAY_MS = 1000;  // Delay between batches in milliseconds

  for (let i = 0; i < users.length; i += BATCH_SIZE) {
    const batch = users.slice(i, i + BATCH_SIZE);

    const emailPromises = batch.map(user => {
      const templateParams = {
        to_email: user.email,
        username: user.firstName,
        email: user.email,
        password: user.password  // Consider security implications
      };

      return emailjs.send('service_k2ksomp', 'template_se50y6n', templateParams);
    });

    try {
      // Wait for all promises in the current batch to settle
      const results = await Promise.allSettled(emailPromises);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          console.log(`Email to ${batch[index].email} sent successfully`);
        } else {
          console.error(`Failed to send email to ${batch[index].email}:`, result.reason);
        }
      });
    } catch (error) {
      console.error('Unexpected error when sending emails:', error);
    }

    // Wait before sending the next batch
    if (i + BATCH_SIZE < users.length) {
      await new Promise(resolve => setTimeout(resolve, DELAY_MS));
    }
  }

  // Optionally, clear users from localStorage after processing
  localStorage.removeItem('csvUsersToUpload');
}
,

async getUsers() {
  this.loading = true;
  // Retrieve the selectedDepartmentId from localStorage
  const selectedDepartmentId = localStorage.getItem('selectedDepartmentId');
  
  // Define the query to fetch documents from the "users" collection where departmentId matches selectedDepartmentId
  const q = query(collection(db, "users"), where("departmentId", "==", selectedDepartmentId));

  let tempUserList = [];

  try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Map through the querySnapshot docs to format the user data
      tempUserList = querySnapshot.docs.map((user) =>
          Object.assign(user.data(), {
              id: user.id,
              disabled: user.data().disabled || false, // Ensure 'disabled' is correctly initialized
          })
      );

      // Update the component's state with the fetched users
      this.users = tempUserList;
  } catch (error) {
      console.error("Error fetching users:", error);
      // Handle any errors, such as displaying a notification to the user
  } finally {
      // Ensure loading is set to false regardless of the outcome
      this.loading = false;
  }
},


    // querySnapshot.forEach((doc) => {
    //   let tempUser = doc.data();
    //   tempUser.id = doc.id;
    //   tempUserList.push(tempUser);
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
.data-table {
  border-radius: 12px;
  box-shadow: 4px 4px 16px lightslategray;
  width: 100%;
}
</style>
