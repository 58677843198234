<template>
  <div>
    <v-navigation-drawer
      v-if="$route.path != '/' && $route.path != '/forgotPassword'"
style="z-index:1"
      v-model="drawer"
      app
      elevation="0"
      class="navigation-drawer pa-4"

    >
    <v-row justify="center" class="my-12">
    <v-img src="../assets/Pocket.png" contain height="110"></v-img>
  </v-row>
  
      <v-list dense style="margin-top:70px">
        <v-list-item
        :class="{ 'active-item': isActiveItem(item.route) }"

          v-for="(item, index) in drawerItems"
          :key="index"
          @click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon class="v-list-item-title">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="v-list-item-title ">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$route.path != '/' && $route.path != '/forgotPassword'"
      style="background-color: rgb(255, 255, 255)"
      app
      flat
    >
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-btn
          icon="$vuetify"
          rounded
          style="background-color: #2a95ae; height: 35px; width: 36px"
          ><v-icon color="white">mdi-menu</v-icon></v-btn
        >
      </v-app-bar-nav-icon>

      <v-row justify="center" class="my-1" style="margin-right: 35px"
        ><h2 style="color: rgb(0, 0, 0)" class="text">POCKET</h2>
        <h2 style="color: #2a95ae" class="text mx-1">MANAGER</h2></v-row
      >

      <!-- Other app bar components -->
    </v-app-bar>
    <!-- Your page content here -->
  </div>
</template>

<script>
import { collection, addDoc, getFirestore } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      drawer: true,

      drawerItems: [
        { title: "User Managment", icon: "mdi-account-details", route: "/home" },
        { title: "Companies", icon: "mdi-city", route: "/companies" },
        { title: "Logout", icon: "mdi-logout", route: "/" },
      ],
    };
  },
  methods: {
    isActiveItem(route) {
    return this.$route.path === route;
  },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    navigate(route) {
      // Check if the target route is the same as the current route
      if (this.$router.currentRoute.path === route) {
        // Already on the target route, so do nothing to avoid the NavigationDuplicated error
        return;
      }
      // If the target route is different, proceed with navigation
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>


.navigation-drawer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%; /* Adjust width to ensure there's no visible outline */
  height: 100%;

  z-index: -1;
}



.active-item .v-list-item-title {
  color: white; /* Sets the text color to white */
  
}
.active-item {
  border-radius: 20px; /* Circular edges */
  background-color: rgb(74, 149, 172); /* Light blue background for active item */
  color: white; /* White text color */
  font-family: "Black Han Sans", sans-serif;
}
</style>
