<template>
    <v-col>
      <v-container>
        
      <v-dialog v-model="dialog" width="500px" height="500px">
              <v-sheet
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="animated-icon"
        color="#2a95ae"
        size="112"
      >
        mdi-check-circle
      </v-icon>
  
      <v-row justify="center" class="text mb-6 my-2"><dv>Add</dv><div class="mx-1" style="color:#2a95ae">Department</div></v-row>
  
  <p class="mb-4 text-medium-emphasis text-body-2">
    Are you sure you want to add

    <br>

  a this Department
  </p>
  
      <v-divider class="mb-4"></v-divider>
  
      <div class="text-center">
        <v-btn
          class="text-none my-2"
          color="#2a95ae"
          rounded
          dark
          variant="flat"

          @click="saveCourse(); saveSchedule()"
          width="200px"
        >
          Yes
        </v-btn>
              <v-btn
          class="text-none mx-2 my-2"
          color="black"
          rounded
          dark
          variant="flat"
          width="200px"
          @click="dialog = false"
      
        >
          No
        </v-btn>
      </div>
    </v-sheet>
      </v-dialog>
      <v-row justify="center">
        <v-col class="mx-3 my-10" cols="12" sm="12" md="10">
          <div class="card">
            <div justify="center">
      <v-card-title class="d-flex justify-center align-center">
  <div class="text my-3">ADD</div>
  <div class="text mx-2" style="color:#2a95ae">DEPARTMENT</div>
</v-card-title>
  </div>
            <!-- <v-row align="center" justify="center">
              <v-col
                cols="6"
                sm="6"
                md="2"
                class="profile mx-9 my-7"
                :style="{ width: '100%' }"
              >
                <img
                  v-if="profilePicture"
                  :src="profilePicture"
                  style="width: 100%; height: 100%; object-fit: cover"
                />
                <video
                  ref="video"
                  autoplay
                  style="width: 100%; height: 100%; object-fit: cover"
                ></video>
                <canvas ref="canvas" style="display: none"></canvas>
              </v-col>
              <v-col class="mx-5" cols="5" sm="5" md="2">
                <v-btn dark color="black" rounded @click="$refs.fileInput.click()"
                  >Upload Photo</v-btn
                >
                <input
                  type="file"
                  name="profilePicture"
                  ref="fileInput"
                  style="display: none"
                  @change="uploadFile"
                  accept="image/*"
                />
              </v-col>
            </v-row> -->
            <v-col>
                 <!-- <v-row>
                <v-col cols="12" sm="12" md="5">
                  <v-select
                    rounded
                    class="mx-3"
      
                    dense
                    label="Category"
                    outlined
                    v-model="category"
                    :items="categoryItems"
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row justify="center">
                <v-col cols="12" sm="12" md="5">
                  <v-text-field
                    rounded
                    dense
                    class="mx-3"

                    label="Department Name"
                    outlined
                    :rules="[
      value => !!value || 'Title is required',
      value => (value && value.length <= 50) || 'Title must be less than or equal to 50 characters'
    ]"
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row justify="center">
                <v-col cols="12" sm="12" md="5">
                  <v-textarea
                    rounded
                    class="mx-3"

                    dense
                    outlined
                    :rules="[
      value => !!value || 'Description is required',
      value => (value && value.length <= 1000) || 'Description must be less than or equal to 1000 characters'
    ]"
                    label="Description"
                    v-model="description"
                  ></v-textarea>
                </v-col>
              </v-row>
          
              <v-row justify="center">
                <v-col cols="12" sm="12" md="5">
                  <v-text-field
                    rounded
                    class="mx-3"

                    dense
                    outlined
                    label="Chat Bot Link"
                    v-model="link"
                  ></v-text-field>
                </v-col>
              </v-row>
          <v-row justify="center">
            <v-btn
            solo
            color="#2a95ae"
            width="180px"
            dark
            rounded
            class="mx-2 my-7"
            @click="dialog = true"
          >
            Add Department
          </v-btn>
          </v-row>
            </v-col>
          
          </div>
  
          
        </v-col>
        <v-overlay style="z-index:99" v-model="loader">         
         <v-progress-circular
    color="white"
    indeterminate
    :size="70"
    :width="12"
  ></v-progress-circular>
</v-overlay>
      </v-row>
          <!-- <v-snackbar color="green" v-model="showBeneficiaryAddedPrompt" top>
        Newspaper added successfully!
      </v-snackbar> -->
      </v-container>
    </v-col>
    
  </template>
  
  <script>
  import { collection, getFirestore, setDoc, doc, getDocs,query,where} from "firebase/firestore";
  import { firebaseApp } from "../firebaseConfig";

  import { getAuth } from "firebase/auth";
  import { ref, uploadBytes, getDownloadURL,getStorage } from "firebase/storage";

  

  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  
  export default {
    data() {
      return {
        code: '',
        loader:false,
        eventCode:'',
        exclusiveEvent: false,
        showBeneficiaryAddedPrompt: false,
        dialog: false,
        eventType: '', // Initialize eventType
        name: "",
        description: "",
        category: "",
        end: "",
        categoryItems: ["Education", "Sport", "Social","Corporate", "Tech", "Automotive","Food", "Animals", "Cultural","Musical", "Travel", "Beauty"],
        profilePicture: null,
        start: "",
        time: null,
        link:"",
        bookings: "",
        options: [],
        menu: false,
        eventCode: null,
        modal: false,
        menu2: false,
        modal2: false,
        type: "newsletter",
        imageUrl: null,
        selectedOption: null,
        // added
        amount: '',
        row: '',
      };
    },
    watch: {
  eventType(newVal) {
    if (newVal === 'Exclusive') {
      this.generateCode();
    } else {
      this.eventCode = ''; // This line clears the event code when "Public" is selected
    }
  },
},

    methods: {
      goToPage(path) {
        this.$router.push({ path });
      },
      async uploadFile(event) {
    try {
        // Get the selected file from the input or use the report property
        const file = event?.target?.files[0] || this.report;

        if (!file) {
            console.error("No file selected or provided in report property.");
            return;
        }

        // Generate a local URL for the selected file for preview
        this.profilePicture = URL.createObjectURL(file);

        // Create a reference for the file in Firebase Storage using its name or current date
        const filename = file.name ? `surveyPicture/${file.name}` : `ID/${Date.now()}_${this.report.name}`;
        const storageRef = ref(storage, filename);

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, file);

        // Get the download URL after the file is uploaded
        const downloadURL = await getDownloadURL(storageRef);
        console.log("File uploaded successfully");
        console.log("Download URL:", downloadURL);

        // Store the download URL in local storage
        localStorage.setItem('downloadURL', downloadURL);

        // Retrieve the download URL from local storage
        const imageUrlFromLocalStorage = localStorage.getItem('downloadURL');
        if (!imageUrlFromLocalStorage) {
            console.warn("No download URL found in local storage.");
            return;
        }

        // Update the imageUrl and profilePicture properties
        this.imageUrl = imageUrlFromLocalStorage;
        this.profilePicture = imageUrlFromLocalStorage; // This ensures profilePicture is updated to the saved URL

    } catch (error) {
        console.error("Error processing or uploading file:", error);
    }
}
,
async generateCode() {
    let unique = false;
    let newCode = '';

    while (!unique) {
      newCode = this.createRandomCode();
      unique = await this.isCodeUnique(newCode);
    }

    this.eventCode = newCode;
  },

  createRandomCode() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  },

  async isCodeUnique(code) {
    const querySnapshot = await getDocs(query(collection(db, "club"), where("eventCode", "==", code)));
    return querySnapshot.empty; // Returns true if no documents with the same code exist
  },
    async saveCourse() {
      try {
        this.generateCode(); // Generate the event code before saving
        // ... the rest of your saveCourse method ...
      } catch (error) {
        console.error("Error creating course: ", error);
      }
    },
  
    async saveCourse() {
  try {
    this.dialog=false;
    this.loader=true;
    const uid = localStorage.getItem('userId'); // gets the uid from local storage
    if (!uid) throw new Error("User is not logged in");

    const selectedClubId = localStorage.getItem('selectedClubId'); // Get the selectedClubId from localStorage
    if (!selectedClubId) throw new Error("No club selected");

    const newCourse = {
      name: this.name,
      category: this.category,
      link: this.link,
      description: this.description,
      dateCreated: new Date(),
      imageUrl: this.profilePicture,
      createdBy: uid, // added this line
      clubId: selectedClubId, // Add the selectedClubId to the document
    };
    this.showBeneficiaryAddedPrompt = true;

    // Assume "department" is the intended collection. If it's supposed to be "event" or another, please replace accordingly.
    await setDoc(doc(collection(db, "department")), newCourse);

    // If there's a need to add a document to the "notification" collection, consider adding that logic here as well

    this.loader=false;
    this.$emit('hideChild');

  } catch (error) {
    console.error("Error creating course: ", error);
    this.loader=false; // Ensure loader is turned off in case of error
  }
},

  
  
    },
  };
  </script>
  
  
  
  <style scoped>
  .profile {
    height: 150px;
    box-shadow: 0px 0px 0px 1px black;
    background-color: white;
    border-radius: 20px;
  }
  .card{
    width: 100%;
    height: 100%;
     background-color: white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius:20pxx
  }
  .image-drop-zone {
    border: 2px dashed white;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: #009bcb;
  }
  .prompt {
    background: rgb(110, 110, 110);
    background: linear-gradient(
      8deg,
      rgba(110, 110, 110, 1) 0%,
      rgba(20, 23, 23, 1) 100%
    );
    width: 100%;
    height: 300px;
    font-size: 18px;
    font-weight: bold;
    padding: 10%;
    color: white;
  }
  .buttons {
    margin: 30px;
  }
  .btn {
    width: 150px;
  }
  .icon {
    font-size: 70px;
    margin: 10px;
  }
  </style>
  