<template>
<v-container>
   <v-dialog v-model="dialog" width="500px" height="500px">
      <v-sheet
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon class="animated-icon" color="#2a95ae" size="112">
          mdi-check-circle
        </v-icon>
        <v-row justify="center" class="text mb-6 my-2"><dv>EDITING</dv><div class="mx-2" style="color:#2a95ae">USER</div></v-row>

        <p class="mb-4 text-medium-emphasis text-body-2">
          Are you sure you want to edit

          <br />

          this user
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none my-2"
            color="#2a95ae"
            rounded
            dark
            variant="flat"
            width="200px"

           @click="updateUser"
          >
            Yes
          </v-btn>
          <v-btn
            class="text-none mx-2 my-2"

            rounded
            dark
            color="black"
    
            @click="dialog = false"
            variant="flat"
            width="200px"
          >
            No
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
           <v-row justify="end" class="mx-2 my-2">
        <v-btn color="#2a95ae" v-model="selectedTab"  :value="1" dark rounded solo @click="dialog = true"> Save Changes</v-btn>
    </v-row>

<v-row>
    <v-col>
        <div class="cardTitle">
Personal Details
        </div>
        <div class="card">
          <v-col cols="12" sm="12" md="6" class="mx-2 my-3">

   <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">First Name</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field outlined solo placeholder="First Name" v-model="firstName" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
          <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Last Name</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field outlined solo placeholder="Last Name" v-model="lastName" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
               <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Email Address</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field outlined solo placeholder="Email Address" v-model="email" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">Phone Number</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field outlined solo placeholder="Phone Number" v-model="cellNum" rounded dense></v-text-field>
                         </v-col>
                   </v-row>
                  <!-- <v-row>
                <v-col cols="12" sm="12" md="3" style="color:black" >
                  <h4 class="my-2">ID Number</h4>
                   </v-col>
                   <v-col cols="12" sm="12" md="9" >
                         <v-text-field outlined solo placeholder="ID Number" v-model="id" rounded dense></v-text-field>
                         </v-col>
                   </v-row> -->
                      </v-col>
        </div>
    </v-col>
</v-row>
    <v-snackbar color="green" v-model="showBeneficiaryAddedPrompt" top>
      User successfully edited!
    </v-snackbar>
</v-container>
</template>
<script>
import {
  collection,
  getDocs,
  getFirestore,
  where,
  setDoc,
  doc,
  query,
  deleteDoc,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export default {

  data() {
    return {
      showBeneficiaryAddedPrompt: false,
      lastName: "",
      firstName: "",
      email: "",
      dialog:false,
      cellNum: "",
      location: "",
      userType: "",
      id: "",
      password: "",
    };
  },
  
  methods: {
    goToPage(path) {
      this.$router.push({ path });
    },
async updateUser() {
  try {
    const userRef = doc(db, "user", this.id);
     this.showBeneficiaryAddedPrompt = true,
    await setDoc(userRef, {
      lastName: this.lastName,
      firstName: this.firstName,
      email: this.email,
      cellNum: this.cellNum,
      userType: this.userType,
    }, { merge: true });
   
    this.goToPage('/dashboard');
  } catch (error) {
    console.log(error);
    // handle error here
  }
}

  },
  props: {
    user: null,
  },
created() {
  this.lastName = this.user.lastName;
  this.firstName = this.user.firstName;
  this.email = this.user.email;
  this.cellNum = this.user.cellNo;
  this.id = this.user.id;
  this.userType = this.user.userType;
 

}

};
</script>

<style scoped>
.cardTitle {
  width: 100%;
  height: 40px;
  background-color: #2a95ae;
  text-align: center;
  padding: 8px;
  color: white;
  border-radius:12px 12px 0px 0px ;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.card{
width: 100%;
  height: 100%;
  text-align: center;
  padding: 7px;
  color: white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius:0px 0px  12px 12px ;
  background-color: white;
}
.card1{
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius:12px 12px 12px 12px
}
</style>
 
