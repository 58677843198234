<template>
  <v-app>
    <Navbar/> <!-- Assuming Navbar is a custom component you want to include -->
    <v-main style="background-color: rgba(42, 149, 174, 0.2); /* 50% opacity */;">
      <router-view></router-view> <!-- Vue Router's outlet for displaying the component matched by the route -->
    </v-main>
  </v-app>
</template>

<script>
// Fixed typo in import statement (iomport -> import)
import Navbar from './components/Navbar.vue' // Correctly import the Navbar component

// Fixed the 'compionents' typo and properly declared the Navbar component in the components object
export default {
  name: 'App',
  components: {
    Navbar // ES6 shorthand for Navbar: Navbar
  },
  data() {
    return {
      // Your data structure goes here
    }
  }
}
</script>
<style>
.text{
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.v-data-table-header {
  background-color: #2b95ae ;
  border-radius: 12px 20px 0px 0px;
  color: white;
}
.v-data-table-header span {
  color: white;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 380;
  font-style: normal;
}

th:nth-child(1) {
  border-radius: 4px 0px 0px 0px;
}

th:last-child {
  border-radius: 0px 4px 0px 0px;
}

v-data-table-header-mobile th {
  border-radius: 13px !important;
}
.v-data-table tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

.v-data-table {
  color: black;
  box-shadow: 0px 0px 0px 1px lightslategray;
}
</style>
